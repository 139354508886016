import _ from 'lodash';
import moment from 'moment';
import gql from 'graphql-tag';

const ME_PURCHASE = gql`
  query MePurchase {
    me {
      id
      recentSubscriptionOrder {
        created
        perDay
        subscription
      }
      recentRecordInput {
        relationType
      }
    }
  }
`;

const getUserLayerInfo = async (client, isInitial = false) => {
  if (isInitial) {
    return {
      userId: '',
      recentSubscriptionOrder: '',
      recentSubscriptionPeriod: '',
      relationType: ''
    };
  }
  const res = await client.query({ query: ME_PURCHASE });
  const data = _.get(res, 'data.me');
  const [userId, recentSubscriptionOrder, recentSubscriptionPeriod, relationType] = _.at(data, [
    'id',
    'recentSubscriptionOrder.created',
    'recentSubscriptionOrder.subscription',
    'recentRecordInput.relationType'
  ]);
  return {
    userId,
    relationType,
    recentSubscriptionOrder: recentSubscriptionOrder
      ? moment(recentSubscriptionOrder).format('YYYYMM')
      : '',
    recentSubscriptionPeriod: recentSubscriptionPeriod || ''
  };
};

export default getUserLayerInfo;
