/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const DIAGNOSIS_REPORT = gql`
  query DiagnosisReport {
    diagnosisReport @client {
      recordResultId
    }
  }
`;

export const UPDATE_DIAGNOSIS_REPORT = gql`
  mutation UpdateDiagnosisReport($recordResultId: String) {
    updateDiagnosisReport(recordResultId: $recordResultId) @client
  }
`;

export const RECORD_RESULT = gql`
  query RecordResult($id: String, $recent: Boolean) {
    recordResult(id: $id, recent: $recent) {
      calorie
      carbohydrate
      created
      egfr
      id
      kalium
      natrium
      phosphorus
      protein
      meal {
        id
        name
        price
      }
      recordInput {
        id
        relationType
        age
        gender
        weight
        height
        creatinineLevel
        kidneyStatus
      }
      updated
      user {
        id
      }
    }
  }
`;
// (비회원)진단결과에서 추천식단만 가져오는 query
export const RECORD_RESULT_MEAL = gql`
  query RecordResult($id: String, $recent: Boolean) {
    recordResult(id: $id, recent: $recent) {
      meal {
        id
        name
      }
    }
  }
`;
// (회원)진단결과에서 추천식단만 가져오는 query
export const USER_RECORD_RESULT_MEAL = gql`
  query AllRecordResult {
    allRecordResult {
      meal {
        id
        name
      }
    }
  }
`;
export const ALL_RECORD_RESULT = gql`
  query AllRecordResult {
    allRecordResult {
      id
      created
      updated
      calorie
      natrium
      protein
      carbohydrate
      kalium
      phosphorus
      egfr
      recordInput {
        id
        relationType
        age
        gender
        weight
        height
        creatinineLevel
        kidneyStatus
      }
      meal {
        id
        name
        price
      }
      user {
        id
      }
    }
  }
`;

export const UPDATE_RECORD_RESULT = gql`
  mutation UpdateRecordResult($id: String) {
    updateRecordResult(id: $id) {
      recordResult {
        id
      }
    }
  }
`;
