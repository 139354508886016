export default {
  perDay: null,
  perWeek: null,
  batchTypes: null,
  subscription: null,
  receivedDay: null,
  option1: 'DEFAULT',
  price: {
    totalMealPrice: 0,
    totalOptionPrice: 0,
    totalPrice: 0,
    discountPrice: 0,
    couponDiscountPrice: 0,
    couponDiscountPercent: 0,
    finalPrice: 0,
    __typename: 'SelectMealPrice'
  },
  __typename: 'SelectMeal'
};
