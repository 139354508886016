export default {
  username: '',
  name: '',
  password: '',
  naverMemberId: '',
  validationKey: '',
  isGuide: false,
  isMarketing: false,
  phoneNumberValidationId: '',
  relations: '',
  __typename: 'SignupSetInfo'
};
