export default {
  applicantName: '',
  zipNo: '',
  address: '',
  addressDetail: '',
  contact1: '',
  deliveryMemo: '',
  isInitialized: false,
  __typename: 'SubscriptionsChangeAddress'
};
