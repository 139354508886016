export default {
  bornYear: '',
  gender: '',
  height: null,
  weight: null,
  creatinineLevel: '0',
  kidneyStatus: '',
  bunStatus: '0',
  proteinuria: '',
  fastingPlasma: null,
  afterTwo: null,
  hemoglobin: null,
  pressure: null,
  totalCholesterol: null,
  liverStatus: null,
  thyroidLevel: '',
  thyroidTreat: '',
  thyroidTsh: '',
  recordDiseases: [],
  recordAvoids: [],
  __typename: 'SignupRecordMore'
};
