import auth from '../../views/auth/cacheState';
import signupSetInfo from '../../views/signupSetPhoneNumber/cacheState';
import signupRecordMore from '../../views/signupBasic/cacheState';
import findPassword from '../../views/findPassword/cacheState';
import findPasswordSuccess from '../../views/findPasswordSuccess/cacheState';
import diagnosis from '../../views/diagnosis/cacheState';
import diagnosisReport from '../../views/diagnosisReport/cacheState';
import selectMeal from '../../views/selectMeal/cacheState';

import checkout from '../../views/checkout/cacheState';
import checkoutCoupon from '../../views/checkoutCoupon/cacheState';
import points from '../../views/points/cacheState';
import checkoutAddress from '../../views/checkoutAddress/cacheState';
import checkoutCard from '../../views/checkoutCard/cacheState';
import checkoutCash from '../../views/checkoutCash/cacheState';
import checkoutEasyPay from '../../views/checkoutEasyPay/cacheState';
import customCheckout from '../../views/customCheckout/cacheState';

import subscriptionsExtend from '../../views/subscriptionsExtend/cacheState';
import subscriptionsChangeAddress from '../../views/subscriptionsChangeAddress/cacheState';

import myPageChangePassword from '../../views/myPageChangePassword/cacheState';

import difficultyOfDietary from '../../views/consultationQualitiativeQuestion/cacheState';
import nutritionStatusGrain from '../../views/consultationQuestionGrainIntake/cacheState';
import nutritionStatusProtein from '../../views/consultationQuestionProteinIntake/cacheState';
import nutritionStatusGrocery from '../../views/consultationQuestionGroceryIntake/cacheState';
import nutritionStatusFruit from '../../views/consultationQuestionFruitIntake/cacheState';
import nutritionStatusDairy from '../../views/consultationQuestionDairyIntake/cacheState';
import {
  nutritionStatusSauce,
  nutritionStatusDessert
} from '../../views/consultationQuestionSauceIntake/cacheState';
import livingHabits from '../../views/consultationQuestionLivingHabits/cacheState';
import consultationPersonalDetail from '../../views/consultationQuestionPersonalDetail/cacheState';
import consultationInfo from '../../views/consultation/cacheState';
import store from '../../views/eatmarket/cacheState';
import cart from '../../views/cart/cacheState';

export default {
  data: {
    auth,
    signupSetInfo,
    signupRecordMore,
    findPassword,
    findPasswordSuccess,
    diagnosis,
    diagnosisReport,
    selectMeal,
    checkout,
    checkoutCoupon,
    points,
    checkoutAddress,
    checkoutCard,
    checkoutCash,
    checkoutEasyPay,
    customCheckout,
    subscriptionsExtend,
    subscriptionsChangeAddress,
    myPageChangePassword,
    nutritionStatusGrain,
    nutritionStatusProtein,
    difficultyOfDietary,
    nutritionStatusGrocery,
    nutritionStatusFruit,
    nutritionStatusDairy,
    nutritionStatusSauce,
    nutritionStatusDessert,
    livingHabits,
    consultationPersonalDetail,
    consultationInfo,
    store,
    cart
  }
};
