export default {
  consultationId: null,
  dietaryPatternId: null,
  dietaryPatternLines: [],
  userType: null,
  consultationType: null,
  schedule: null,
  applyDueDate: null,
  recordInputId: null,
  scheduleId: null,
  __typename: 'ConsultationInfo'
};
