export const nutritionStatusSauce = {
  oilUnit: null,
  sugarUnit: null,
  artificialSugarUnit: null,
  __typename: 'NutritionStatusSauce'
};

export const nutritionStatusDessert = {
  desserts: [],
  dessertUnit: null,
  __typename: 'NutritionStatusDessert'
};
