export default {
  breakfastMenuIndex: 0,
  breakfastQuantityIndex: 0,
  breakfastUnit: null,
  lunchMenuIndex: 0,
  lunchQuantityIndex: 0,
  lunchUnit: null,
  dinnerMenuIndex: 0,
  dinnerQuantityIndex: 0,
  dinnerUnit: null,
  __typename: 'NutritionStatusProtein'
};
