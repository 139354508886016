/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const RECORD_MORE = gql`
  query RecordMore {
    recordMore {
      id
      bornYear
      gender
      weight
      height
      creatinineLevel
      kidneyStatus
      bunStatus
      proteinuria
      fastingPlasma
      afterTwo
      hemoglobin
      pressure
      totalCholesterol
      liverStatus
      recordAvoids {
        avoidNutrition
      }
      recordDiseases {
        diseaseType
      }
    }
  }
`;

export const AUTH = gql`
  query Auth {
    auth @client {
      isLoggedIn
    }
  }
`;

export const UPDATE_AUTH = gql`
  mutation UpdateAuth($isLoggedIn: Boolean) {
    updateAuth(isLoggedIn: $isLoggedIn) @client
  }
`;
