export default {
  relationType: null,
  gender: null,
  bornYear: '',
  weight: '',
  height: '',
  kidneyStatus: null,
  creatinineLevel: '',
  checkDiagnosis: '',
  diabetesStatus: null,
  pregnantStatus: null,
  FBS: '',
  twoHrPG: '',
  glycosylatedHemoglobin: '',
  weightChange: null,
  edemaOrAscites: null,
  anorexia: null,
  albumin: '',
  hemoglobin: '',
  cancerCategory: null,
  __typename: 'Diagnosis'
};
