export default {
  birth: '',
  cardNumber: '',
  expiryMM: '',
  expiryYY: '',
  pwd2digit: '',
  selectedCardId: null,
  selectedInstallment: '',
  activeCardId: null,
  __typename: 'CheckoutCard'
};
